// eslint-disable-next-line simple-import-sort/imports -- sorting puts these imports as last block which breaks styles of PanelCardsItem
import 'styles/styles.scss'
import '@invitae/ids-sass/ids-sass.scss'
import '@invitae/nucleobase/dist/main.css'
import '@invitae/ids-sass/dist/main.css'
import '@invitae/header-footer/dist/main.css'
import '@invitae/ssr-core/dist/main.css'
import '@invitae/physician-cart/dist/main.css'
import '@invitae/cms-blocks/dist/main.css'
import 'react-loading-skeleton/dist/skeleton.css'

import * as React from 'react'
import TagManager from 'react-gtm-module'
import {QueryClient, QueryClientProvider} from 'react-query'
import {
  analyticsConfig,
  cartConfig,
  contentfulClientCredentials,
  globalTextCopiesConfig,
  headerConfig,
  shellNucleobaseConfig,
  DEPRECATED_PRODUCT_TYPES,
} from 'config'
import {AppProps} from 'next/app'
import Head from 'next/head'
import {useRouter} from 'next/router'
import thirdPartyScripts from 'thirdPartyScriptsData'
import CartContextAuthWrapper, {setCartAuthentication} from 'wrapper/CartContextAuthWrapper'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {NucleobaseShell, ToggleConfig, countryCodeIndicatorFromPath} from '@invitae/nucleobase'
import SessionTimeout from '@invitae/session-timeout'
import {InternalServerErrorPage, NotFoundPage} from '@invitae/ssr-core'

import MainCategoryContext from 'components/MainCategory/MainCategoryContext'
import {URL_GENE_REGEX, URL_TEST_REGEX} from 'constants/specialCases'
import {useFeatureFlags} from 'hooks/useFeatureFlags'
import {useSessionTimeout} from 'hooks/useSessionTimeout.hooks'
import useUserAuthenticationStatus from 'hooks/useUserAuthenticationStatus.hooks'
import initializeLogRocket from 'utils/initializeLogRocket'
import {PageProps} from 'pages/[[...id]]'
import {transformFeatureFlags} from 'utils/transformFeatureFlags'
import {camelCase} from 'change-case'
import {cleanUpUrlFromParams} from 'components/MainCategory/utils'

const MAX_RETRIES = 3
const HTTP_STATUSES_NOT_TO_RETRY = [404, 400]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, e) => {
        if (failureCount > MAX_RETRIES) {
          return false
        }

        const error = e as any

        if (HTTP_STATUSES_NOT_TO_RETRY.includes(error.response?.status || error.status || 0)) {
          return false
        }

        return true
      },
      staleTime: 5 * 60 * 1000, // 5 minutes before data becomes stale and needs to be re-fetched
    },
  },
})

const shouldDisplay404Page = (isCustomPage: boolean, pageProps: PageProps, featureFlags: Record<string, boolean>) => {
  if (!isCustomPage && !pageProps.page?.fields?.sections) return true
  // https://invitae.jira.com/browse/CELT-1914
  // if it's a panel page or a gene page and there is only 1 gene and it's product type is NIPS or Carrier, we should display 404 page
  // if there are multiple genes, the logic for filtering out unsupported product types is in src/components/GeneDetail/index.tsx line 49

  if (pageProps?.panelData?.panel?.productType || pageProps?.geneData?.length === 1) {
    const productType = pageProps?.panelData?.panel?.productType || pageProps?.geneData?.[0]?.productType
    if (!featureFlags.cx_ordering_carrier_nips_exception && DEPRECATED_PRODUCT_TYPES.includes(productType || ''))
      return true
  }
  return false
}

const MyApp = ({Component, pageProps}: AppProps<PageProps>) => {
  const router = useRouter()
  const {authState, user} = useUserAuthenticationStatus({onIsAuthenticated: setCartAuthentication})
  const {sessionTimeoutConfig} = useSessionTimeout()
  const {featureFlags: featureFlagsResponse, isLoading: areFlagsLoading} = useFeatureFlags(user)
  const featureFlags = {...transformFeatureFlags(featureFlagsResponse), isLoading: areFlagsLoading}
  React.useEffect(() => {
    if (process.env.INVITAE_GTM_TAG_ID) {
      TagManager.initialize({
        gtmId: process.env.INVITAE_GTM_TAG_ID,
      })
    }
  }, [])

  React.useEffect(() => {
    if (user) {
      initializeLogRocket(user)
    }
  }, [user])

  const [, path] = countryCodeIndicatorFromPath(router.asPath)
  const url = cleanUpUrlFromParams(path)

  const isCustomPage = !!(url.match(URL_TEST_REGEX) || url.match(URL_GENE_REGEX))

  const toggleConfig: ToggleConfig = {
    appName: 'test-catalog-frontend',
    featureFlags: Object.entries(featureFlags).reduce(
      (flags, [flagKey, flagVal]) => {
        flags[camelCase(flagKey)] = flagVal
        return flags
      },
      {} as Record<string, any>,
    ),
    projectName: 'customer-experience',
  }

  if (!isCustomPage && pageProps.errorCode) {
    return <InternalServerErrorPage />
  }

  if (shouldDisplay404Page(isCustomPage, pageProps, featureFlags)) {
    return <NotFoundPage />
  }

  const locationConfig = {
    apiGateway: process.env.API_GATEWAY as string,
    domainFallback: pageProps?.domain || '',
  }
  return (
    <UserAuthStatusContext.Provider value={{authState}}>
      <MainCategoryContext.Provider
        value={{mainCategory: pageProps.mainCategoryData ?? {}, sponsoredTestingData: pageProps.sponsoredTestingData}}
      >
        <CartContextAuthWrapper featureFlags={featureFlags}>
          <QueryClientProvider client={queryClient}>
            <NucleobaseShell
              Head={Head}
              analyticsConfig={analyticsConfig({currentPath: router.asPath})}
              cartConfig={cartConfig({
                featureFlags,
              })}
              contentfulConfig={contentfulClientCredentials}
              countryCode={pageProps.countryCode || 'US'}
              enableCountryLocationControls
              globalTextCopiesConfig={globalTextCopiesConfig}
              headerConfig={headerConfig({currentPath: router.asPath})}
              locationConfig={locationConfig}
              nucleobaseConfig={shellNucleobaseConfig}
              thirdPartyScripts={thirdPartyScripts}
              toggleConfig={toggleConfig}
            >
              {isCustomPage ? <Component {...pageProps} featureFlags={featureFlags} /> : pageProps.page}
            </NucleobaseShell>
            <div className="session-timeout">
              <SessionTimeout config={sessionTimeoutConfig} enabled={authState.isAuthenticated} />
            </div>
          </QueryClientProvider>
        </CartContextAuthWrapper>
      </MainCategoryContext.Provider>
    </UserAuthStatusContext.Provider>
  )
}

export default MyApp
